import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import type {ImageComponent} from '@/types';
import {AlignmentEnum, ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {LiHtml} from '@/components/shared/HtmlElements';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Section from '@/components/brochureV2/primitive/Section/Section';
import Image from '@/components/shared/Image/Image';

interface DisclaimerComponent {
  disclaimer?: string;
  disclaimerIndicator?: string;
}

interface DisclaimersProps {
  alignment?: AlignmentEnum;
  colorScheme?: ColorSchemeEnum;
  disclaimers?: DisclaimerComponent[];
  image?: ImageComponent;
  className?: string;
  disclaimerClass?: string;
  spacerOneClass?: string;
  spacerTwoClass?: string;
  listClassName?: string;
  wrapperClassName?: string;
}

/**
 * @deprecated Please use `src/components/shared/Disclaimers` instead.
 */
export default function Disclaimers({
  alignment,
  className,
  disclaimers,
  disclaimerClass,
  colorScheme,
  image,
  spacerOneClass,
  spacerTwoClass,
  listClassName,
  wrapperClassName,
  ...props
}: DisclaimersProps) {
  if (!disclaimers) {
    return null;
  }

  const currentAlignment = alignment || AlignmentEnum.Left;
  const isCenter = currentAlignment === AlignmentEnum.Center;
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const {srcSet, sizes, alt, imageClassName}: any = image || {};
  return (
    <Section
      sectionName="disclaimers"
      className={cn(className, sectionSchemeClasses[currentScheme])}
      {...props}
    >
      <div className="container">
        <Spacer size="3xl" className={twMerge('top-spacer', spacerOneClass)} />
        <div
          className={cn(
            'flex flex-col',
            {
              'items-start sm:items-center sm:flex-row': !isCenter,
              'items-center': isCenter,
            },
            wrapperClassName,
          )}
        >
          {image && srcSet && (
            <Image
              className={cn(
                'mb-6 h-[190px] w-[190px] object-cover',
                {
                  'sm:mr-6 sm:mb-0': !isCenter,
                },
                imageClassName,
              )}
              srcSet={srcSet}
              sizes={sizes}
              alt={alt}
            />
          )}
          <ul
            className={cn(
              {
                'm-auto text-center': isCenter,
              },
              listClassName,
            )}
          >
            {disclaimers &&
              disclaimers.map((disclaimerItem, index) => {
                const supNumber = index + 1;
                let htmlString = disclaimerItem.disclaimer;
                if (disclaimerItem.disclaimerIndicator) {
                  htmlString = `<sup style="font-size: 10px" class="align-middle mr-1">${disclaimerItem.disclaimerIndicator}</sup>${htmlString}`;
                }

                return (
                  <LiHtml
                    className={cn(disclaimerClass, 'richtext mb-2')}
                    key={`${supNumber}-disclaimer`}
                  >
                    {htmlString}
                  </LiHtml>
                );
              })}
          </ul>
        </div>
        <Spacer
          size="3xl"
          className={twMerge('bottom-spacer', spacerTwoClass)}
        />
      </div>
    </Section>
  );
}
